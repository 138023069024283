<template>
  <div class="mainContent">
    <!-- <div class="groupTabs">
      <div
        v-for="item in groupTabs"
        :key="item.groupValue"
        @click="$emit('update:groupVal', item.groupValue)"
        :class="{ active: groupVal == item.groupValue }"
      >
        {{ item.groupText }}
      </div>
    </div> -->
    <div v-for="(group, groupKey) in groupData" :key="groupKey + 'head'">
      <div class="head">
        <div class="groupWrap">
          <div class="group">{{ group[0].thirdGroup ? $t('home.thirdGroup') : groupText(group[0].sph) }}</div>
        </div>
        <div class="detailWrap">
          <div>{{ $t("home.Sai") }}</div>
          <div>{{ $t("home.Win") }}</div>
          <div>{{ $t("home.Lose") }}</div>
          <div>{{ $t("home.integration") }}</div>
        </div>
      </div>
      <div class="content" :key="groupKey + 'content'">
        <div :class="['row', {'thirdGroup': group[0].thirdGroup}]" v-for="(item, key) in group" :key="key">
          <div class="groupWrap">
            <div>
              <span class="index">{{ key + 1 }}</span>
              <img
                v-if="item.tlg"
                :src="item.tlg"
                alt=""
              />
              <img
                v-else
                :src="require('@/assets/imgs/comm/outDefault'+themeHotfix+'.svg')"
                alt=""
              />
              <span>{{ item.tnm }}</span>
            </div>
          </div>
          <div class="detailWrap">
            <div>{{ item.w + item.d + item.l }}</div>
            <div>{{ `${item.w}` }}</div>
            <div>{{ `${item.l}` }}</div>
            <div>{{ item.p }}</div>
          </div>
          <div v-if="key == 0 " class="areaTitle">
            {{$t('home.Promotion zone')}}
          </div>
        </div>
      </div>
    </div>
    <div class="explain" v-if="active != 52">
      <div class="stage">
        <div class="block"></div>
        <div>{{ $t("home.Promotion zone") }}</div>
        <!-- <div class="block undetermined"></div>
        <div>{{ $t("home.undetermined") }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { sphList, groupTypeList } from "@/utils/filedList";
import { mapState } from "vuex";

export default {
  name: "Group",
  props: ["groupData", "groupVal", 'active'],
  data() {
    return {
      groupTabs: [
        { groupValue: 50, groupText: groupTypeList[50][this.getLocal()] },
        { groupValue: 51, groupText: groupTypeList[51][this.getLocal()] },
        { groupValue: 52, groupText: groupTypeList[52][this.getLocal()] },
      ],
    };
  },
  computed: {
    themeHotfix() {
      return this.common.theme == 'dark' ? '-dark' : ''
    },
    ...mapState(["common"]),
  },
  methods: {
    groupText(sph) {
      return sphList[sph][this.getLocal()];
    },
    stageText() {
      return this.active == 51 ? groupTypeList[8][this.getLocal()] : groupTypeList[51][this.getLocal()];
    },
    stageText1() {
      return groupTypeList[52][this.getLocal()];
    },
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #f4f4f4;
  padding-bottom: 75px;
  .groupTabs {
    // padding: 5px 8px;
    display: flex;
    border-radius: 0;
    & > div {
      flex: 1;
      border-radius: 5px;
      background: #fff;
      text-align: center;
      line-height: 30px;
      color: #5a6375;
      font-size: 13px;
      margin-right: 5px;
      border: 1px solid transparent;
      &:last-child {
        margin: 0;
      }
    }
    .active {
      border: 1px solid #D80709;
      color: #D80709;
      font-weight: 600;
    }
  }
  .explain {
    // display: flex;
    padding: 6px 0;
    .stage {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 4px 16px;
      background: #ffffff;
      // border: 1px solid #eaedf6;
      border-radius: 8px;
      font-weight: 500;
      font-size: 13px;
      color: #131a3e;

      .block {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        margin-right: 6px;
        background: #FFD2D4;
        &.undetermined {
          background: #E5E8F3;
          margin-left: 20px;
        }
      }
    }
  }
  & > div {
    border-radius: 8px;
    margin-bottom: 4px;
    overflow: hidden;
  }
  .head {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    background: #fbfbfb;
    & > div {
      flex: 1;
      text-align: center;
    }

    .group {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      // width: 31px;
      padding: 0 6px;
      height: 18px;
      left: 16px;
      top: 11px;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      background: #131a3e;
      border-radius: 5px;
    }
  }
  .groupWrap {
    display: flex;
    flex: 1;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .detailWrap {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: none !important;
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
  }
  .content {
    overflow: auto;
    .row {
      display: flex;
      align-items: center;
      height: 40px;
      background: #fff;
      border-bottom: 0.5px solid  #EDEEF1;
      padding: 0 16px;
      position: relative;
      .areaTitle{
        padding: 0 4px;
        border-radius: 0 0 0 9px;
        height: 14.222px;
        font-size: 10px;
        color: #fff;
        background: #D80709;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
      }
      &:nth-child(1),
      &:nth-child(2) {
        background: #FFD2D4;
      }
      // &:nth-child(3) {
      //   background: #E5E8F3;
      // }
      &.noColor{
        background: #fff;
      }
      & > div {
        flex: 1;
        font-size: 13px;
        text-align: center;

        &:first-child {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-weight: 500;
          }
          .index {
            font-family: "DIN";
            font-weight: 700;
            font-size: 12px;
            margin-left: 5px;
          }
        }
        img {
          margin: 0 8px;
          width: 18px;
          height: 18px;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    .thirdGroup{
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        background: #FFD2D4;
      }
    }
  }
}
</style>

<style lang="scss">
.dark{
  .mainContent {
  background: #000;
  color: #fff;
  .groupTabs {
    & > div {
      background: #151618;
      color: #fff;
    }
  }
  .explain {
    .stage {
      background: #151618;
      color: #fff;

      .block {
        background: #FFD2D4;
        &.undetermined {
          background: #E5E8F3;
        }
      }
    }
  }
  .head {
    background: #292C30;
    .group {
      color: #fff;
      background: #000000;
    }
  }
  .content {
    .row {
      background: #151618;
      border-bottom: 0.5px solid  #2B2C30;
      .areaTitle{
        color: #151618;
        background: #D80709;
      }
      &:nth-child(1),
      &:nth-child(2) {
        background: #2A0000;
      }
      // &:nth-child(3) {
      //   background: #E5E8F3;
      // }
      &.noColor{
        background: #151618;
      }
    }
    .thirdGroup{
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        background: #2A0000;
      }
    }
  }
}
}
</style>